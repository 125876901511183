import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appDecimalNumberInput]'
})
export class DecimalNumberInputDirective {
  @Input() clearOnFocusWhenZero = true;

  private regex: RegExp = new RegExp(/^\-?\d*[\.]?\d{0,2}$/g);
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Delete',
    'Enter',
    'Meta',
    'Home',
    '-',
    'ArrowLeft',
    'ArrowRight'
  ];
  private eventKey?: string;
  private caretStartPosition?: number;

  constructor(private el: ElementRef) {
    if (this.el.nativeElement.getAttribute('type') !== 'text') {
      this.el.nativeElement.setAttribute('type', 'text');
      throw new Error('Detected non-text type appDecimalNumberInput: ' + this.el.nativeElement.classList);
    }
  }

  @HostListener('paste', [])
  onEvent() {
    setTimeout(() => {
      this.checkAfterPaste();
    }, 50);
  }

  @HostListener('focus', [])
  onFocus() {
    if (this.el.nativeElement.value === '0' && this.clearOnFocusWhenZero) {
      this.el.nativeElement.value = null;
    }
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // selenium 'type' command
    if (!event.key) return true;

    this.setEventKey(event);

    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(this.eventKey!) !== -1) return true;

    // Allow CRTL and Meta keys
    if ((event.ctrlKey || event.metaKey)) {
      if (this.eventKey === 'a' || this.eventKey === 'c' || this.eventKey === 'r' || this.eventKey === 'x') return true;
      if (this.eventKey === 'v') {
        setTimeout(() => {
          this.checkAfterPaste();
        }, 50);
        return true;
      }
    }

    this.checkNewValueAllowed(event, this.eventKey!);
    return true
  }

  private checkAfterPaste() {
    let newValue = this.el.nativeElement.value + '';
    // remove spaces
    newValue = newValue.split(' ').join('');
    // remove Euro
    newValue = newValue.split('€').join('');
    // dots for commas
    newValue = newValue.split(',').join('.');


    if (newValue !== this.el.nativeElement.value) {
      this.el.nativeElement.value = newValue;
      this.el.nativeElement.classList.add('highlight-copy-changed');
      this.el.nativeElement.dispatchEvent(new Event('input'));
      setTimeout(() => {
        this.el.nativeElement.classList.remove('highlight-copy-changed');
      }, 2500);
    }
  }

  private setEventKey(event: KeyboardEvent) {
    if (event.key === ',') {
      this.eventKey = '.';
    } else {
      this.eventKey = event.key;
    }
  }

  private checkNewValueAllowed(event: any, input: string) {
    let newValue: string = this.el.nativeElement.value;
    newValue = this.handleSelectedText(newValue);
    newValue = this.handleCaretPosition(newValue, input);
    this.handleNewValue(newValue, event);
  }

  private handleSelectedText(newValue: string) {
    const selectedText: string | undefined = this.getSelectionText();
    if (selectedText) {
      newValue =
        this.replaceBetweenString(newValue, this.el.nativeElement.selectionStart, this.el.nativeElement.selectionEnd, '');
    }
    return newValue;
  }

  private replaceBetweenString(input: any, start: any, end: any, replaceWith: string): string {
    return input.substring(0, start) + replaceWith + input.substring(end);
  }

  private getSelectionText() {
    let selectionText: string | undefined = undefined;

    if (window.hasOwnProperty('getSelection')) {
      selectionText = this.el.nativeElement.value.substring(
        this.el.nativeElement.selectionStart,
        this.el.nativeElement.selectionEnd
      );
    } else {
      // For IE
      // @ts-ignore
      if (document.selection && document.selection.type !== 'Control') {
        // @ts-ignore
        selectionText = document.selection.createRange().text;
      }
    }

    return selectionText;
  }

  private handleCaretPosition(newValue: string, input: string) {
    this.caretStartPosition = this.el.nativeElement.selectionStart;
    const nextBeforeCaret = newValue.slice(0, this.el.nativeElement.selectionStart);
    const nextAfterCaret = newValue.slice(this.el.nativeElement.selectionStart);
    newValue = nextBeforeCaret + input + nextAfterCaret;
    return newValue;
  }

  private handleNewValue(newValue: string, event: KeyboardEvent) {
    event.preventDefault();
    if (newValue && String(newValue).match(this.regex)) {
      this.el.nativeElement.value = newValue;
      this.el.nativeElement.dispatchEvent(new Event('input'));
      this.setCaretPosition();
    }
  }

  private setCaretPosition() {
    if (this.el.nativeElement.createTextRange) {
      const range = this.el.nativeElement.createTextRange();
      range.move('character', (this.caretStartPosition! + 1));
      range.select();
      return true;
    } else {
      // (el.selectionStart === 0 added for Firefox bug)
      if (this.el.nativeElement.selectionStart || this.el.nativeElement.selectionStart === 0) {
        this.el.nativeElement.focus();
        this.el.nativeElement.setSelectionRange((this.caretStartPosition! + 1), (this.caretStartPosition! + 1));
        return true;
      } else { // fail city, fortunately this never happens (as far as I've tested) :)
        this.el.nativeElement.focus();
        return false;
      }
    }
  }
}
