import {ElementRef, Pipe, PipeTransform} from '@angular/core';
import {UseMockDataService} from '../services/use-mock-data.service';
import {delay, Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Pipe({
  name: 'mockHttpClient',
  standalone: true
})
export class MockHttpClientPipe implements PipeTransform {

  constructor(
    private useMockDataService: UseMockDataService,
    private httpClient: HttpClient,
  ) {
  }

  transform<T>(mockHttpClientOptions: {
    method: 'PUT' | 'POST' | 'PATCH' | 'DELETE' | 'GET',
    url: string,
    urlVars?: string,
    comment: string,
    elementRef?: ElementRef
    body?: any,
    params?: any,
    headers?: {
      names: string[],
      headers: HttpHeaders
    },
    mockReturnData?: T,
    delay?: number,
  }): Observable<T> {
    if (this.useMockDataService.getUseMockData()) {
      const info: any = {
        request: {
          method: mockHttpClientOptions.method,
          urlAbs: mockHttpClientOptions.url,
        },
        opmerking: mockHttpClientOptions.comment,
      };

      if (mockHttpClientOptions.mockReturnData) info.response = mockHttpClientOptions.mockReturnData;
      if (mockHttpClientOptions.elementRef) info.request.elementRef = mockHttpClientOptions.elementRef;
      if (mockHttpClientOptions.urlVars) info.request.urlVar = mockHttpClientOptions.urlVars;
      if (mockHttpClientOptions.params) info.requestParams = mockHttpClientOptions.params;
      if (mockHttpClientOptions.headers) info.headers = mockHttpClientOptions.headers.names.map(name => {
        return {
          name,
          value: mockHttpClientOptions.headers?.headers.get(name)
        }
      });
      if (mockHttpClientOptions.body) info.requestBody = mockHttpClientOptions.body;
      console.info(`[MOCK API]:\n`, info);
      return of(mockHttpClientOptions.mockReturnData as T).pipe(delay(mockHttpClientOptions.delay || 300));
    } else {
      switch (mockHttpClientOptions.method) {
        case 'PUT':
          return this.httpClient.put<T>(mockHttpClientOptions.url, mockHttpClientOptions.body, {
            params: mockHttpClientOptions.params,
            headers: mockHttpClientOptions.headers?.headers
          });
        case 'POST':
          return this.httpClient.post<T>(mockHttpClientOptions.url, mockHttpClientOptions.body, {
            params: mockHttpClientOptions.params,
            headers: mockHttpClientOptions.headers?.headers
          });
        case 'PATCH':
          return this.httpClient.patch<T>(mockHttpClientOptions.url, mockHttpClientOptions.body, {
            params: mockHttpClientOptions.params,
            headers: mockHttpClientOptions.headers?.headers
          });
        case 'DELETE':
          return this.httpClient.delete<T>(mockHttpClientOptions.url, {
            params: mockHttpClientOptions.params,
            headers: mockHttpClientOptions.headers?.headers
          });
        case 'GET':
          return this.httpClient.get<T>(mockHttpClientOptions.url, {
            params: mockHttpClientOptions.params,
            headers: mockHttpClientOptions.headers?.headers
          });
      }
    }
  }

}

