import {EventEmitter, Injectable} from '@angular/core';

import {JsonPipe} from '@angular/common';
import * as signalR from "@microsoft/signalr";
import {take} from "rxjs/operators";
import {environment} from 'src/environments/environment';
import {UseMockDataService} from './use-mock-data.service';
import {User} from '../types/user.interface';

export type WebSocketMessage = { action: string; data?: any };

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private environment = environment;
  webSocketAutoRetryPending = false;
  webSocket?: signalR.HubConnection;
  webSocketError$: EventEmitter<string | null> = new EventEmitter<string | null>();
  webSocketNext$: EventEmitter<string> = new EventEmitter<string>();
  webSocketComplete$: EventEmitter<void> = new EventEmitter<void>();
  received$: EventEmitter<{ action: string[], data: any }> = new EventEmitter<{ action: string[]; data: any }>();
  webSocketConnectedCount = 0;
  webSocketConnected$: EventEmitter<void> = new EventEmitter<void>();
  webSocketReconnected$: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private jsonPipe: JsonPipe,
    private useMockDataService: UseMockDataService,
  ) {
  }

  connect(user: User): void {
    if (this.useMockDataService.getUseMockData()) {
      this.handleWebSocketConnectionSuccess()
    } else {
      if (this.webSocket) {
        this.webSocket.stop().finally(() => {
          this.webSocket = undefined;
          this.setUpNewWebSocketConnection(user.apiToken)
        });
      } else {
        this.setUpNewWebSocketConnection(user.apiToken);
      }
    }
  }

  private setUpNewWebSocketConnection(apiToken?: string) {
    if(!apiToken) return;
    this.webSocket = new signalR.HubConnectionBuilder().withUrl(
      environment.ws, {accessTokenFactory: () => apiToken, headers: {version: this.environment.version}}
    ).build();

    this.webSocket.on('receive', (message: { action: string, data?: any }) => {
      this.handleIncomingMessage(message);
    });

    this.startWebSocketConnection();

    this.webSocket.onclose(() => {
      this.webSocketComplete$.emit();
    });
  }

  private async startWebSocketConnection() {
    try {
      await this.webSocket?.start();
      this.handleWebSocketConnectionSuccess();
    } catch (err) {
      this.handleError(err)
    }
  }

  private handleWebSocketConnectionSuccess() {
    this.webSocketAutoRetryPending = false;
    this.webSocketConnectedCount++;
    if (this.webSocketConnectedCount > 1) {
      this.webSocketReconnected$.emit();
      this.webSocketConnected$.emit();
    } else {
      this.webSocketConnected$.emit();
    }
  }

  private handleIncomingMessage(message: WebSocketMessage): void {
    if (!message) return;
    if (this.useMockDataService.getUseMockData()) {
      console.info(
        '[MOCK] WS->CLIENT \n',
        JSON.parse(JSON.stringify(message))
      );
    }
    this.webSocketNext$.emit();
    this.webSocketError$.emit(null);
    this.received$.emit({
      action: message.action.split('/'),
      data: message.data
    });
  }

  private handleError(event: Event | CloseEvent | Error | any): void {
    if (event instanceof Error) {
      this.webSocketError$.emit(`WS Error: ${this.jsonPipe.transform(event)})`);
    }
    if (event instanceof Event) {
      this.webSocketError$.emit('Unexpected WS-error');
    }
    if (event instanceof CloseEvent) {
      console.warn(event);
      this.webSocketError$.emit(`WS close event: ${this.jsonPipe.transform(event.code)})`);
    }
    this.webSocket?.stop();
  }


  mockReceiveMessage(message: WebSocketMessage): void {
    this.handleIncomingMessage(message);
  }

  sendMessage(message: WebSocketMessage, mockResponse?: WebSocketMessage, mockResponseTimeout: number = 300) {
    if (!this.useMockDataService.getUseMockData()) {
      if (!this.webSocket) return;
      if (this.webSocket.state !== 'Connected') {
        this.webSocketConnected$.pipe(take(1)).subscribe(() => {
          this.invokeWs(message);
        });
      } else {
        this.invokeWs(message);
      }
    } else {
      console.info(
        '[MOCK] CLIENT->WS \n',
        message,
      );
      setTimeout(() => {
        if (mockResponse) this.mockReceiveMessage(mockResponse);
      }, mockResponseTimeout);
    }
  }

  private async invokeWs(message: WebSocketMessage) {
    try {
      await this.webSocket?.invoke('send', message);
    } catch (err) {
      // we can ignore this error; https://stackoverflow.com/questions/37624322/uncaught-in-promise-undefined-error-when-using-with-location-in-facebook-gra
    }
  }


  clear(): void {
    this.webSocketAutoRetryPending = false;
    this.webSocket?.off('receive');
    this.webSocket?.stop().finally(() => {
      this.webSocket = undefined;
    });
  }
}
