import {EnvironmentInterface} from './environment.interface';


declare const require: any;

export const environment: EnvironmentInterface = {
  isProduction: true,
  apiBaseUrl: 'https://test-spss.app-dysto.nl:443',
  apiPrefix: 'V1/webapp',
  useProxy: false,
  version: require('../../package.json').version,
  ws: 'https://test-spss.app-dysto.nl:443/hub',
  redirectToApiOffLineAfterApiTimeOut: true,
  apiServerResponseTimeoutMs: 60000,
  defaultUseMockData: false,
  features: {
    canUseMockData: false,
  }
};
