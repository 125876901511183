import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[appIntegerNumberInput]'
})
export class IntegerNumberInputDirective {

  @HostListener('keydown', ['$event'])
  onKeydown(event: KeyboardEvent) {
    if(!event.key) return true;
    return !(event.key === '.' || event.key === ',');
  }

  @HostListener('paste', [])
  onEvent() {
    setTimeout(() => {
      let newValue = this.el.nativeElement.value.replace(/[^0-9]/g, '');
      if (newValue !== this.el.nativeElement.value) {
        this.el.nativeElement.value = newValue;
        this.el.nativeElement.dispatchEvent(new Event('input'));
      }
    }, 10);
  }

  constructor(private el: ElementRef) {
  }


}
