import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FriendOfTheAuction} from '../../types/friend-of-the-auction.interface';
import {MockHttpClientPipe} from '../../pipes/mock-data-fetch.pipe';
import {mockData} from '../../../mock-data/mock-data';

@Injectable({
  providedIn: 'root'
})
export class PublicFriendsOfTheAuctionService {
  private initStarted = false;
  private friends$ = new BehaviorSubject<FriendOfTheAuction[] | null>(null);

  constructor(
    private mockHttpClientPipe: MockHttpClientPipe
  ) {
  }

  init() {
    if (this.initStarted) return;
    this.initStarted = true;
    this.getFriendsOfTheAuctionFromApi();
  }

  private getFriendsOfTheAuctionFromApi() {
    this.mockHttpClientPipe.transform<FriendOfTheAuction[]>({
      method: 'GET',
      url: `public/friends_of_the_auction`,
      comment: 'Alle vrienden van de veiling ophalen, let op; beperkte data mee geven',
      mockReturnData: mockData?.friendsOfTheAuction ?? []
    }).subscribe(friends => {
      if (friends?.length) {
        friends = friends.map(friend => {
          if (friend.website) {
            if(!friend.website.startsWith('http')) {
              friend.website = `http://${friend.website}`;
            }
          }
          return friend;
        })
      }
      this.friends$.next(friends);
    });
  }

  getFriends() {
    return this.friends$;
  }
}
