<mat-dialog-content>

  <app-dialog-header title="Authenticatie"></app-dialog-header>

  <div *ngIf="environment.features.canUseMockData && mockDataForm"
       class="misc">
    <form [formGroup]="mockDataForm">
      <mat-slide-toggle formControlName="useMockData"
                        color="accent"
                        class="no-space-bottom">
        <b class="color--accent">MOCK DATA</b>
      </mat-slide-toggle>
    </form>

    <button mat-flat-button
            color="accent"
            (click)="onReloadClick()">
      <mat-icon>refresh</mat-icon>
      Herlaad pagina
    </button>
  </div>

  <form *ngIf="!(userService.user$ | async)"
        [formGroup]="loginForm">
    <mat-form-field>
      <mat-label>Gebruikersnaam</mat-label>
      <input matInput
             appAutofocus
             placeholder="E-mailadres"
             type="email"
             formControlName="email">
    </mat-form-field>

    <mat-form-field>
      <mat-label>Wachtwoord</mat-label>
      <input matInput
             type="password"
             formControlName="password">
    </mat-form-field>
  </form>

</mat-dialog-content>

<mat-dialog-actions>

  <button mat-icon-button
          color="warn"
          mat-dialog-close
          matTooltip="Annuleren">
    <mat-icon>clear</mat-icon>
  </button>

  <button *ngIf="!environment.features.canUseMockData"
          mat-icon-button
          color="accent"
          matTooltip="Herlaad pagina"
          (click)="onReloadClick()">
    <mat-icon>refresh</mat-icon>
  </button>

  <button *ngIf="!(userService.user$ | async); else showLogOutButton"
          mat-flat-button
          color="primary"
          [disabled]="loginForm.invalid || loginForm.untouched"
          [appButtonIsPending]="loginIsPending"
          (click)="onLogin()">
    <mat-icon>login</mat-icon>
    Inloggen
  </button>

  <ng-template #showLogOutButton>
    <button mat-flat-button
            color="primary"
            [appButtonIsPending]="loginIsPending"
            (click)="onLogout()">
      <mat-icon>logout</mat-icon>
      Uitloggen
    </button>
  </ng-template>

</mat-dialog-actions>
