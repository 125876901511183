import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {appConfig} from '../app-config';
import {NgSelectConfig} from '@ng-select/ng-select';
import {User} from './types/user.interface';
import {UserService} from './services/user.service';
import {MatDialog} from '@angular/material/dialog';
import {HideMainNavService} from './services/hide-main-nav.service';
import {APP_LANGUAGE_NATIVES, APP_LANGUAGES, I18nService} from './services/i18n.service';
import {LoginDialogComponent} from './components/login-dialog/login-dialog.component';
import {APP_ROUTES} from './app.navigation';
import {WsUsersService} from './private/services/ws-users.service';
import {MatSidenav} from '@angular/material/sidenav';
import {environment} from '../environments/environment';
import {debounceTime, map} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [
    './app.component.scss'
  ]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private routerEvents$: Subscription | undefined;
  private clicks: Date[] = [];
  protected readonly APP_LANGUAGE_NATIVES = APP_LANGUAGE_NATIVES;
  protected readonly APP_LANGUAGES = APP_LANGUAGES;

  @ViewChild('sidenav', {static: true}) sidenav!: MatSidenav;
  isApiOfflinePage = false;
  isPrivatePage = false;
  loggedInUserCount$: Observable<number>;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private ngSelectConfig: NgSelectConfig,
    private matDialog: MatDialog,
    private userService: UserService,
    private wsUsersService: WsUsersService,
    public i18nService: I18nService,
    public mainNavService: HideMainNavService,
  ) {
    this.i18nService.init();
    this.ngSelectConfig.appendTo = 'body';

    this.loggedInUserCount$ = this.wsUsersService.wsUsers$.pipe(
      debounceTime(50),
      map(wsUsers => {
        return wsUsers?.length || 0;
      }))
  }

  ngOnInit(): void {
    this.setPageBooleans(location.pathname);
    this.tryLoginUserFromLocalStorage();
    this.subscribeToRouter();
  }

  private setPageBooleans(path: string) {
    this.isApiOfflinePage = path.startsWith(`/${APP_ROUTES.apiOffLine}`);
    this.isPrivatePage = path.startsWith(`/${APP_ROUTES.private}`);
  }

  private tryLoginUserFromLocalStorage() {
    const localStorageUser = localStorage.getItem(appConfig.localStorageKeys.user);
    if (!localStorageUser) return;
    this.userService.setUser(JSON.parse(localStorageUser) as User);
  }

  private subscribeToRouter() {
    this.routerEvents$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.changeDetectorRef.detectChanges();
        this.sidenav.close();
      } else if (event instanceof NavigationEnd) {
        this.setPageBooleans(event.urlAfterRedirects);
        localStorage.setItem(appConfig.localStorageKeys.lastSuccessFulNavigationUrl, event.urlAfterRedirects);
      } else if (event instanceof NavigationCancel || event instanceof NavigationError) {
        localStorage.removeItem(appConfig.localStorageKeys.lastSuccessFulNavigationUrl);
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => document.getElementById('loading-wrapper')?.remove(), 400);
  }

  handleLogoClick() {
    this.clicks.push(new Date());
    while (this.clicks.length > 5) {
      this.clicks.shift();
    }
    if (this.clicks.length === 5 && this.clicks[4].getTime() - this.clicks[0].getTime() < 2000) {
      this.clicks = [];
      this.matDialog.open(LoginDialogComponent, {
        disableClose: true
      });
    }
  }

  ngOnDestroy(): void {
    this.routerEvents$?.unsubscribe();
  }

  protected readonly environment = environment;
}
