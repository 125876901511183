import {Injectable} from '@angular/core';
import {appConfig} from '../../app-config';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UseMockDataService {
  private useMockData: boolean = UseMockDataService.getMockDataInitialValue();

  static getMockDataInitialValue(): boolean {
    const localStorageValue = localStorage.getItem(appConfig.localStorageKeys.useMockData);
    if (localStorageValue) {
      return JSON.parse(localStorageValue);
    } else {
      return environment.defaultUseMockData;
    }
  }

  getUseMockData(): boolean {
    return this.useMockData;
  }

  setUseMockData(useMockData: boolean): void {
    this.useMockData = useMockData;
    localStorage.setItem(appConfig.localStorageKeys.useMockData, JSON.stringify(this.useMockData));
  }
}
