import {FriendOfTheAuction} from '../../app/types/friend-of-the-auction.interface';

export const friendsOfTheAuctionMockData = [
  {
    id: '123',
    city: 'Hoevelaken',
    website: 'https://stalvanderlaack.nl',
    fullName: 'Paola en Peter Collignon'
  },
  {
    id: '1231',
    companyName: 'Stal v.d. Leyenhof',
    city: 'Kerkdriel',
    fullName: 'Henk de Grouw'
  },
  {
    id: '112312223',
    city: 'Bavel',
    website: 'www.shetlandstaldetoekomst.nl',
    fullName: 'Ruud van Raak'
  },
  {
    id: 'aa123',
    city: 'Sint-Michielsgestel',
    website: 'www.staldehorzik.nl',
    fullName: 'Hein en Helma van Roij'
  },
  {
    id: '123123sa123',
    companyName: 'Timmerwerk op maat',
    city: 'Dussen',
    website: 'www.chrisvanandel.nl',
    fullName: 'Chris van Andel'
  },
  {
    id: '123',
    city: 'De Rips',
    fullName: 'Jo van de Berg'
  },
  {
    id: '12hhhh3',
    companyName: 'Stal de Buckshoeve',
    city: 'Den Bosch',
    fullName: 'Hans van den Broek'
  },
  {
    id: '123hjj',
    city: 'Overasselt',
    website: 'www.stalsportview.nl',
    fullName: 'Martin Broens'
  },
  {
    id: '123hhhhhh',
    companyName: 'Stal van \'t Terp',
    city: 'Brakel',
    fullName: 'Gijs Dijkhof'
  },
  {
    id: '123jjjjjj',
    city: 'Chieming (BRD)',
    website: 'www.elmhorst-checkenponies.de',
    fullName: 'Stephan Elmer'
  },
  {
    id: '123bbbbbbb',
    companyName: 'Stal van \'t Schut',
    city: 'Lunteren',
    fullName: 'Willem van Harten'
  },
  {
    id: '123hhhh',
    companyName: 'Stal van \'t Sterrebos',
    city: 'Nederhemert',
    fullName: 'Peter Keijnemans'
  },
  {
    id: '123555555',
    companyName: 'Stal Wordragen',
    website: 'Ammerzoden',
    fullName: 'Fam. van Loon'
  }
] as FriendOfTheAuction[];
