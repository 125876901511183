import {Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {Observable, TimeoutError} from 'rxjs';
import {tap, timeout} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Router} from "@angular/router";

import {environment} from '../environments/environment';
import * as moment from 'moment/moment';
import {appConfig} from '../app-config';
import {UserService} from './services/user.service';
import {ApiErrorDialogComponent} from './components/api-error-dialog/api-error-dialog.component';
import {APP_LANGUAGES} from './services/i18n.service';
import {APP_ROUTES} from './app.navigation';
import {MockHttpClientPipe} from './pipes/mock-data-fetch.pipe';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private dialogRef?: MatDialogRef<ApiErrorDialogComponent, any>;
  private transFormedHeaders: {
    Accept?: string,
    apitoken?: string,
    userLanguage?: string,
  } = {};
  private tryApiServerPingPending = false;

  constructor(
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
    private mockHttpClientPipe: MockHttpClientPipe,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const transformedRequest = this.getTransformedRequest(request);
    const isPingUrl = this.getIsPingUrl(request.url);
    return next.handle(transformedRequest).pipe(
      timeout(this.getTimeOut(request)),
      tap((response) => {
          if (response instanceof HttpResponse) {
            this.handleHttpResponse(response);
          }
        },
        (response: HttpErrorResponse) => {
          this.handleErrorResponse(response, isPingUrl);
        }
      )
    );
  }

  private getTimeOut(request: HttpRequest<any>): number {
    if (this.getIsPingUrl(request.url)) {
      return 5000;
    } else {
      return environment.apiServerResponseTimeoutMs;
    }
  }

  private getTransformedRequest(request: HttpRequest<any>) {
    if (this.getIsThirdPartyApi(request.url)) return request;
    this.transFormedHeaders = {};
    this.transFormedHeaders['Accept'] = 'application/json';

    const apiToken = this.userService.getUser()?.apiToken;
    if (apiToken) this.transFormedHeaders['apitoken'] = apiToken;

    this.transFormedHeaders['userLanguage'] = localStorage.getItem(appConfig.localStorageKeys.language) || APP_LANGUAGES[0];

    return request.clone({
      url: this.getTransformedUrl(request.url),
      setHeaders: this.transFormedHeaders
    });
  }

  private getIsThirdPartyApi(url: string): boolean {
    if (!url) return false;
    return url.startsWith('https://google.com');
  }

  private getTransformedUrl(url: string): string {
    if (url.split('/assets/version.json').length > 1) return url;

    if (url.split('/assets/i18n/').length > 1) return `${url}?version=${environment.version}`;

    if (environment.useProxy) {
      return this.getTransformedQueryParamUrl(`proxyApi/${environment.apiPrefix}/${url}`);
    } else {
      return this.getTransformedQueryParamUrl(`${environment.apiBaseUrl}/${environment.apiPrefix}/${url}`);
    }
  }

  private getTransformedQueryParamUrl(url: string): string {
    const urlSplit = url.split('?');
    if (urlSplit.length === 1) {
      return `${url}/`
    } else {
      return url
    }
  }

  private handleHttpResponse(response: HttpResponse<any>): void {
    const apiTokenExpireDate = response.headers.get('api-token-expire-date');
    if (!apiTokenExpireDate) return;
    this.userService.setApiTokenExpireDate(moment(apiTokenExpireDate).toDate());
  }

  private handleErrorResponse(response: HttpErrorResponse | TimeoutError, isPingUrl = false) {
    if (response instanceof TimeoutError) {
      if (!isPingUrl) {
        this.tryApiServerPing(response);
      }
    }
    if (response instanceof HttpErrorResponse) {
      if (response?.url && response.url.split('/assets/version.json').length > 1) return;
      this.handleHttpErrorResponse(response);
    }
  }

  private handleHttpErrorResponse(response: HttpErrorResponse) {
    if (response?.url && this.getIsThirdPartyApi(response.url)) return;
    if (response.url && response.status === 401) {
      this.handleUnauthorizedUser();
    } else if (response.status === 404 || response.status === 500) {
      this.handleApiServerPossiblyOffline(response);
    } else {
      this.showApiErrorDialog(response);
    }
  }

  private handleUnauthorizedUser() {
    this.userService.unSetUser();
    this.router.navigateByUrl('/');
  }

  private handleApiServerPossiblyOffline(response: HttpErrorResponse) {
    if (!response.url) return;
    if (!this.getIsPingUrl(response.url)) {
      this.tryApiServerPing(response);
    }
  }

  private getIsPingUrl(url: string): boolean {
    let split = url.split('/');
    split = split.filter(splitPart => splitPart !== '');
    if (split.length !== 2) return false;
    return !(split[0] !== 'public' && split[1] !== 'ping');
  }

  private tryApiServerPing(response: HttpErrorResponse | TimeoutError) {
    if (this.tryApiServerPingPending) return;
    this.tryApiServerPingPending = true;
    this.mockHttpClientPipe.transform({
      method: 'GET',
      comment: 'Checkt of back-end online is',
      url: `public/ping`,
      mockReturnData: {}
    }).subscribe(
      {
        next: () => {
          this.tryApiServerPingPending = false;
          this.showApiErrorDialog(response);
        },
        error:() => {
          if (environment.redirectToApiOffLineAfterApiTimeOut) {
            this.tryApiServerPingPending = false;
            localStorage.setItem(appConfig.localStorageKeys.urlToOpenAfterApiBackOnline, location.href)
            this.router.navigateByUrl(`/${APP_ROUTES.apiOffLine}`);
          }
        }
      });
  }

  private showApiErrorDialog(response: HttpErrorResponse | TimeoutError) {
    this.dialogRef?.close();
    this.dialogRef = this.dialog.open(ApiErrorDialogComponent, {data: response});
  }
}
