import {AdTypes, Advertiser} from '../../../app/types/advertiser.interface';

export const advertisersMockData = [
  {
    id: '123',
    email: 'jbausch@ziggo.nl',
    companyName: 'Stal Ankeveen',
    address: 'Stichts End 78',
    zipcode: '0000AA',
    city: 'Ankeveen',
    phoneNumber: '+31622454520',
    website: 'https://maps.app.goo.gl/pmj7KA8etNAiVeGi6',
    remarks: 'Ik vond het een duidelijk formulier',
    transactionId: '123983',
    colorType: AdTypes.COLOR,
    pageType: AdTypes.FULL_PAGE,
    advertiserSubmittedPDF: true,
    pdfFileName: 'drukbestand.pdf',
  },
  {
    id: '2222222',
    email: 'info@stalvankairos.com',
    companyName: 'Stal van Kairos',
    address: 'Wilgenroosweg 1',
    zipcode: '5757RC',
    city: 'Liessel',
    phoneNumber: '+31646162858',
    website: 'https://www.stalvankairos.com/',
    transactionId: '6548788484',
    colorType: AdTypes.BLACK_AND_WHITE,
    pageType: AdTypes.FULL_PAGE,
    logoPath: 'https://images.spss.nu/mock-images/sponsors/kairos.jpeg'
  },
  {
    id: '321',
    email: 'info@tuincentrumoosterhout.nl',
    companyName: 'Tuincentrum Oosterhout',
    address: 'Damweg 7',
    zipcode: '4905BS',
    city: 'Oosterhout',
    phoneNumber: '+31162451852',
    website: 'https://www.tuincentrumoosterhout.nl/',
    transactionId: '654789',
    colorType: AdTypes.BLACK_AND_WHITE,
    pageType: AdTypes.HALF_PAGE,
    advertiserSubmittedPDF: false,
    logoPath: 'https://images.spss.nu/mock-images/sponsors/tuincentrum-oosterhout.png'
  },
  {
    id: '3322',
    email: 'info@koltec.nl',
    companyName: 'Koltec BV',
    address: 'SPINVELD 39',
    zipcode: '4815 HV',
    city: 'Breda',
    phoneNumber: '+31765297575',
    website: 'https://www.koltec.nl',
    transactionId: '897987',
    colorType: AdTypes.COLOR,
    pageType: AdTypes.HALF_PAGE,
    advertiserSubmittedPDF: false,
    logoPath: 'https://images.spss.nu/mock-images/sponsors/koltec.jpeg'
  },
  {
    id: '323232',
    email: 'info@havens.nl',
    companyName: 'Havens',
    address: 'Kalverstraat 2a',
    zipcode: '5823 AK',
    city: 'Maashees',
    phoneNumber: '+31(0)478-638238',
    website: 'https://www.havens.nl/',
    transactionId: '4545454',
    colorType: AdTypes.BLACK_AND_WHITE,
    pageType: AdTypes.FULL_PAGE,
    advertiserSubmittedPDF: false,
    logoPath: 'https://images.spss.nu/mock-images/sponsors/havens.png'
  },
] as Advertiser[];
