import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NgSelectModule} from '@ng-select/ng-select';
import {MtxDatetimepickerModule} from '@ng-matero/extensions/datetimepicker';
import {ButtonIsPendingDirective} from './directives/button-is-pending.directive';
import {ElementBreakpointDirective} from './directives/element-breakpoint.directive';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatBadgeModule} from '@angular/material/badge';
import {TranslateModule} from '@ngx-translate/core';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {IntegerNumberInputDirective} from './directives/integer-number-input.directive';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {DecimalNumberInputDirective} from './directives/decimal-number-input.directive';
import {VarDirective} from './directives/ng-var.directive';
import {DBConfig, NgxIndexedDBModule} from 'ngx-indexed-db';


export const RegisterPonyPhotosTable = 'registerPonyPhotos';

const dbConfig: DBConfig = {
  name: 'SPSS_DB',
  version: 1,
  objectStoresMeta: [{
    store: RegisterPonyPhotosTable,
    storeConfig: {keyPath: 'id', autoIncrement: true},
    storeSchema: [
      {name: 'tempPonyId', keypath: 'tempPonyId', options: {unique: false}},
      {name: 'tempPhotoId', keypath: 'tempPhotoId', options: {unique: true}},
      {name: 'order', keypath: 'order', options: {unique: false}},
      {name: 'base64', keypath: 'base64', options: {unique: false}}
    ]
  }]
};

const directivesImportAndExport: any[] = [
  ButtonIsPendingDirective,
  ElementBreakpointDirective,
  IntegerNumberInputDirective,
  DecimalNumberInputDirective,
  VarDirective
];

const modules: any[] = [
  // Angular
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,

  // MAT
  MatFormFieldModule,
  MatInputModule,
  MatMenuModule,
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatBottomSheetModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatSlideToggleModule,
  MatBadgeModule,

  // 3rd party
  NgSelectModule,
  MtxDatetimepickerModule,
  PdfViewerModule,
  NgxIndexedDBModule.forRoot(dbConfig)
];

@NgModule({
  declarations: [
    ...directivesImportAndExport
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...directivesImportAndExport,
    ...modules,
    TranslateModule
  ]
})
export class SharedModule {
}
