import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  NgResizeObserver,
  ngResizeObserverProviders,
} from "ng-resize-observer";
import {Subscription} from "rxjs";
import {appConfig} from '../../../app-config';
import {isNumeric} from '../../../utils/is-numeric';

@Directive({
  selector: '[appElementBreakpoint]',
  providers: [ngResizeObserverProviders]
})
export class ElementBreakpointDirective implements OnInit, OnChanges, OnDestroy {
  @Input() customBreakPointRangeIndex?: number;
  @Input() useCustomBreakPointRangeIndex = false;
  @Output() elBp: EventEmitter<number> = new EventEmitter<number>();

  private resizeChanged$?: Subscription;
  breakPointRangeIndex?: number;

  constructor(
    private resize$: NgResizeObserver,
    private el: ElementRef
  ) {
  }

  ngOnInit(): void {
    if (this.useCustomBreakPointRangeIndex) {
      this.applyClassList(this.customBreakPointRangeIndex);
      return;
    }
    this.resizeChanged$ = this.resize$.subscribe(resize => {
      const elWidth = resize.contentRect.width;
      const breakPointRangeIndex = appConfig.breakPointRanges.findIndex(breakPointRange => {
        return (elWidth >= breakPointRange.min) && (elWidth <= breakPointRange.max);
      });

      if (breakPointRangeIndex === this.breakPointRangeIndex) return;
      this.breakPointRangeIndex = breakPointRangeIndex;
      this.elBp.emit(breakPointRangeIndex);
      this.applyClassList(breakPointRangeIndex);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.useCustomBreakPointRangeIndex) return;
    if (!changes['customBreakPointRangeIndex']) return;
    if (changes['customBreakPointRangeIndex'].previousValue === changes['customBreakPointRangeIndex'].currentValue) return;
    this.applyClassList(changes['customBreakPointRangeIndex'].currentValue);
  }

  private applyClassList(breakPointRangeIndex?: number) {
    if(!isNumeric(breakPointRangeIndex)) return;
    if(breakPointRangeIndex === undefined) return;
    appConfig.breakPointRanges.forEach((range, i) => {
      if (i <= breakPointRangeIndex) {
        this.el.nativeElement.classList.add(`data-elbp-from-${range.name}`);
        this.el.nativeElement.classList.remove(`data-elbp-until-${range.name}`);
        return
      }
      if (i >= breakPointRangeIndex) {
        this.el.nativeElement.classList.add(`data-elbp-until-${range.name}`);
        this.el.nativeElement.classList.remove(`data-elbp-from-${range.name}`);
        return
      }
    })
  }

  ngOnDestroy(): void {
    this.resizeChanged$?.unsubscribe();
  }
}
