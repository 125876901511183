import {Component, Inject} from '@angular/core';
import {SharedModule} from '../../../../../shared/shared.module';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Advertiser} from '../../../../../types/advertiser.interface';
import {DialogHeaderComponent} from '../../../../../components/dialog-header/dialog-header.component';

@Component({
  selector: 'app-public-advertiser-dialog',
  standalone: true,
  imports: [SharedModule, DialogHeaderComponent],
  templateUrl: './public-advertiser-dialog.component.html',
  styleUrls: ['./public-advertiser-dialog.component.scss']
})
export class PublicAdvertiserDialogComponent {
  advertiser?: Advertiser;

  constructor(
    @Inject(MAT_DIALOG_DATA) matData: Advertiser
  ) {
    this.advertiser = matData;
  }
}
