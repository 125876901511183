import {Component, Input} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {NgOptimizedImage} from '@angular/common';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    NgOptimizedImage
  ],
})
export class DialogHeaderComponent {
  @Input() title!: string;
}
