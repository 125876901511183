import {Customer} from '../../app/types/customer.interface';
import {appConfig} from '../../app-config';

export const customersMockData = [
  {
    id: '123',
    firstName: 'Jan',
    lastName: 'Janssen',
    email: 'klant_1@example.com',
    phoneNumber: '0612345678',
    bankDetails: 'NL12ABCD0123456789',
    country: 'Nederland',
    city: 'Amsterdam',
    postalCode: '1234 AA',
    street: 'Amsterdamsestraatweg',
    houseNumber: '12',
    houseNumberAddition: 'A',
    lockedByUserId: null,
    lockedByUserName: null,
    costsBenefitsViaBank: null,
    costsBenefitsViaCash: null,
    invoiceUrl: '/assets/pdf/customer-invoice.pdf',
    poniesSoldCount: 0,
    poniesBoughtCount: 1,
    benefitsTotal: 0,
    costSubTotal: 0,
    costAuctionAddedCosts: 0,
    costTotal: 0,
    costBenefitsTotal: 0,
    hasPaymentsPending: false,
    auctionAgreementPDFUrl: '/assets/pdf/auction-agreement.pdf',
    auctionAgreementExcelUrl: '/assets/excel/auction-agreement.xlsx',
  },
  {
    id: '321',
    firstName: 'Johanna',
    lastName: 'Willemsen',
    email: 'klant_2@example.com',
    phoneNumber: '0612345678',
    bankDetails: 'NL12ABCD0123456789',
    country: 'België',
    city: 'Brussel',
    postalCode: '5448 BW',
    street: 'Brusselsestraat',
    houseNumber: '875',
    houseNumberAddition: null,
    lockedByUserId: appConfig.mockValues.otherUserId,
    lockedByUserName: appConfig.mockValues.otherUserName,
    costsBenefitsViaBank: 600,
    costsBenefitsViaCash: 150,
    invoiceUrl: '/assets/pdf/customer-invoice.pdf',
    poniesSoldCount: 1,
    poniesBoughtCount: 0,
    benefitsTotal: 400,
    costSubTotal: 0,
    costAuctionAddedCosts: 0,
    costTotal: 0,
    costBenefitsTotal: 400,
    hasPaymentsPending: false,
  },
  {
    id: '878',
    firstName: 'Philippe',
    lastName: 'de la Fontaine',
    email: 'klant_3@example.com',
    phoneNumber: '0612345678',
    bankDetails: 'NL12ABCD0123456789',
    country: 'Frankrijk',
    city: 'Parijs',
    postalCode: '4578 AA',
    street: 'Parijsestraat',
    houseNumber: '445',
    houseNumberAddition: null,
    lockedByUserId: appConfig.mockValues.loggedInUserId,
    lockedByUserName: appConfig.mockValues.loggedInUserName,
    costsBenefitsViaBank: null,
    costsBenefitsViaCash: null,
    invoiceUrl: '/assets/pdf/customer-invoice.pdf',
    poniesSoldCount: 1,
    poniesBoughtCount: 1,
    benefitsTotal: 100,
    costSubTotal: 200,
    costAuctionAddedCosts: 12,
    costTotal: 212,
    costBenefitsTotal: -112,
    hasPaymentsPending: true,
  },
] as Customer[];
