import {Component, OnInit} from '@angular/core';
import {SharedModule} from '../../../../shared/shared.module';
import {APP_ROUTES} from '../../../../app.navigation';
import {Advertiser} from '../../../../types/advertiser.interface';
import {MockHttpClientPipe} from '../../../../pipes/mock-data-fetch.pipe';
import {mockData} from '../../../../../mock-data/mock-data';
import {MatDialog} from '@angular/material/dialog';
import {PublicAdvertiserDialogComponent} from './public-advertiser-dialog/public-advertiser-dialog.component';

@Component({
  selector: 'app-public-footer-advertisers',
  templateUrl: './public-footer-advertisers.component.html',
  styleUrls: ['./public-footer-advertisers.component.scss'],
  standalone: true,
  imports: [
    SharedModule
  ]
})
export class PublicFooterAdvertisersComponent implements OnInit {
  protected readonly APP_ROUTES = APP_ROUTES;

  advertisers?: Advertiser[];

  constructor(
    private mockHttpClientPipe: MockHttpClientPipe,
    private matDialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.mockHttpClientPipe.transform<Advertiser[]>({
      method: 'GET',
      url: `public/advertisers`,
      comment: 'Alle sponsoren ophalen, let op; beperkte data mee geven',
      mockReturnData: mockData?.advertisers ? mockData.advertisers.map(advertiser => {
        return {
          email: advertiser.email,
          companyName: advertiser.companyName,
          address: advertiser.address,
          zipcode: advertiser.zipcode,
          city: advertiser.city,
          phoneNumber: advertiser.phoneNumber,
          website: advertiser.website,
          logoPath: advertiser.logoPath,
        } as Advertiser
      }) : []
    }).subscribe(advertisers => {
      if (advertisers?.length) {
        advertisers = advertisers.map(advertisers => {
          if (advertisers.website) {
            if(!advertisers.website.startsWith('http')) {
              advertisers.website = `http://${advertisers.website}`;
            }
          }
          return advertisers
        })
      }
      this.advertisers = this.shuffleAdvertisers(advertisers);
    });
  }

  private shuffleAdvertisers(advertisers: Advertiser[] | null) {
    if (!advertisers) return [];
    let currentIndex = advertisers.length;
    while (currentIndex != 0) {
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [advertisers[currentIndex], advertisers[randomIndex]] = [advertisers[randomIndex], advertisers[currentIndex]];
    }
    return advertisers;
  }

  onAdvertiserClick(advertiser: Advertiser) {
    this.matDialog.open(PublicAdvertiserDialogComponent, {data: advertiser});

  }
}
