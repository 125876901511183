import {Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {interval, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from '../environments/environment';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {TranslatePipe} from '@ngx-translate/core';

@Component({
  selector: 'app-version-checker',
  template: '',
  styleUrls: []
})
export class VersionCheckerComponent implements OnInit, OnDestroy {
  @Input() forceReload = false;

  private interval$?: Subscription;
  private snackBar$?: Subscription;

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange(): void {
    if (!document.hidden) this.checkNewVersion();
  }

  constructor(
    private httpClient: HttpClient,
    private matSnackBar: MatSnackBar,
    private translatePipe: TranslatePipe,
  ) {
  }

  ngOnInit(): void {
    this.checkNewVersion();
    this.subscribeToApiExpireChecker();
  }

  private subscribeToApiExpireChecker() {
    this.interval$ = interval(60000).pipe(
      map(() => {
        this.checkNewVersion();
      })).subscribe();
  }

  private checkNewVersion() {
    this.httpClient.get<{
      version: string
    }>(`/assets/version.json?cacheBuster=${new Date().getTime()}`).subscribe(response => {
      this.handleVersion(response.version);
    });
  }

  private handleVersion(version: string) {
    const newVersionSplit: string[] = version.split('.');
    const currentVersionSplit: string[] = environment.version.split('.');
    const currentVersionDiffersFromNewVersion = currentVersionSplit.find((versionPath, i) => {
      return Number(versionPath) !== Number(newVersionSplit[i]);
    });
    if (currentVersionDiffersFromNewVersion) {
      this.handleOtherVersionAvailable();
    }
  }

  private handleOtherVersionAvailable() {
    if (this.forceReload) {
      window.location.reload();
    } else {
      this.showSnackbar();
    }
  }

  private showSnackbar() {
    this.snackBar$?.unsubscribe();

    const ref = this.matSnackBar.open(
      this.translatePipe.transform(marker('generic.Nieuwe versie beschikbaar')),
      this.translatePipe.transform(marker('generic.Herlaad & update')), {
        duration: 30000,
        horizontalPosition: 'end',
        verticalPosition: 'bottom',
      });

    this.snackBar$ = ref.afterDismissed().subscribe(response => {
      if (response.dismissedByAction) {
        window.location.reload();
      }
    })
  }

  ngOnDestroy(): void {
    this.interval$?.unsubscribe();
    this.snackBar$?.unsubscribe();
  }
}
