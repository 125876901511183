<app-public-footer-advertisers></app-public-footer-advertisers>

<app-public-footer-friends-of-the-auction></app-public-footer-friends-of-the-auction>

<div class="links">
  <a href="mailto:informatie@shetlandponyselectsale.nl">
    <mat-icon inline>email</mat-icon>
    <span>{{ 'generic.Mail ons' | translate }}</span>
  </a>

  <a href="tel:+31610877926">
    <mat-icon inline>phone</mat-icon>
    <span>+316 108 77 926</span>
  </a>

  <a href="https://www.facebook.com/ShetlandponySelectsale/" target="_blank">
    <mat-icon inline>open_in_new</mat-icon>
    <span>Facebook</span>
  </a>

  <a routerLink="/{{APP_ROUTES.aboutUs}}/{{APP_ROUTES.shetlandPonySelectSale}}">
    <mat-icon inline>contact_mail</mat-icon>
    <span>{{ 'generic.Contact' | translate }}</span>
  </a>
</div>