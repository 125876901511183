import {Sponsor} from './sponsor.interface';

export enum AdTypes {
  COLOR = 'COLOR',
  BLACK_AND_WHITE = 'BLACK_AND_WHITE',
  FULL_PAGE = 'FULL_PAGE',
  HALF_PAGE = 'HALF_PAGE'
}

export interface Advertiser extends Sponsor {
  colorType?: AdTypes.COLOR | AdTypes.BLACK_AND_WHITE;
  pageType?: AdTypes.FULL_PAGE | AdTypes.HALF_PAGE;
  // PDF
  advertiserSubmittedPDF?: boolean;
  pdfFileName?: string;
  pdfBase64?: string;
  pdfPath?: string;
  // Logo
  logoFileName?: string;
  logoBase64?: string;
  logoPath?: string;
}


