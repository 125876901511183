<div *ngIf="advertisers"
     class="marquee-container">

  <ng-container *ngIf="advertisers.length * 10 as animationSeconds">

    <!-- plot list 4 times to make do the marquee magic -->
    <ng-container *ngTemplateOutlet="listOfAdvertisers"></ng-container>
    <ng-container *ngTemplateOutlet="listOfAdvertisers"></ng-container>
    <ng-container *ngTemplateOutlet="listOfAdvertisers"></ng-container>
    <ng-container *ngTemplateOutlet="listOfAdvertisers"></ng-container>

    <ng-template #listOfAdvertisers>
      <div class="list"
           [style.animation-duration]="animationSeconds + 's'">
        <ng-container *ngFor="let advertiser of advertisers; let index = index">

          <div class="item"
               (click)="onAdvertiserClick(advertiser)">
            <img *ngIf="advertiser.logoPath as logo; else showText"
                 src="{{logo}}"
                 alt="{{advertiser.companyName}}"/>

            <ng-template #showText>
              <h6>{{ advertiser.companyName }}</h6>
            </ng-template>

          </div>

        </ng-container>
      </div>
    </ng-template>

  </ng-container>

</div>
