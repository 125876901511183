import {Injectable} from '@angular/core';

import * as moment from 'moment/moment';
import {TranslateService} from '@ngx-translate/core';
import {appConfig} from '../../app-config';
import {BehaviorSubject, Observable} from 'rxjs';

export const APP_LANGUAGES = ['nl', 'en'] as const;

export const APP_LANGUAGE_NATIVES = {
  [APP_LANGUAGES[0]]: 'Nederlands',
  [APP_LANGUAGES[1]]: 'English',
} as const;

export const APP_LANGUAGE_LOCALE_IDS = {
  [APP_LANGUAGES[0]]: 'nl-NL',
  [APP_LANGUAGES[1]]: 'en-GB',
} as const;

export type AppLanguage = typeof APP_LANGUAGES[number];

export function GetLocaleId(): string {
  const localStorageVal = localStorage.getItem(appConfig.localStorageKeys.language) as AppLanguage;
  if(!localStorageVal) {
    return APP_LANGUAGE_LOCALE_IDS[APP_LANGUAGES[0]];
  } else {
    return `${APP_LANGUAGE_LOCALE_IDS[localStorageVal] ?? APP_LANGUAGE_LOCALE_IDS[APP_LANGUAGES[0]]}`;
  }
}


@Injectable({
  providedIn: 'root'
})
export class I18nService {
  private currentLanguage$ = new BehaviorSubject<AppLanguage | null>(null);
  languageLoaded = false;

  constructor(
    private translateService: TranslateService,
  ) {
  }

  init() {
    this.setLanguage(this.getInitialLanguage(), false);
  }

  private getInitialLanguage(): AppLanguage {
    const localStorageLanguage = localStorage.getItem(appConfig.localStorageKeys.language);
    if (localStorageLanguage) {
      return localStorageLanguage as AppLanguage;
    } else {
      const navigatorLanguage = navigator.language;

      const foundMatchingLanguage = APP_LANGUAGES.find(appLanguage => {
        navigatorLanguage.startsWith(appLanguage);
      });

      if (foundMatchingLanguage) {
        return foundMatchingLanguage;
      } else {
        return APP_LANGUAGES[0];
      }
    }
  }

  setLanguage(newLanguage: AppLanguage, doReload: boolean) {
    if (newLanguage === this.currentLanguage$.getValue()) return;
    localStorage.setItem(appConfig.localStorageKeys.language, newLanguage);
    if (doReload) {
      location.reload();
    } else {
      this.currentLanguage$.next(newLanguage);
      this.translateService.setDefaultLang(newLanguage);
      this.translateService.use(newLanguage).subscribe(() => {
        this.languageLoaded = true;
        moment.locale(newLanguage);
      });
    }
  }

  getLanguageAsObservable(): Observable<AppLanguage | null> {
    return this.currentLanguage$.pipe();
  }

  getLanguageDirect(): AppLanguage | null {
    return this.currentLanguage$.getValue();
  }
}
