import {Component, ElementRef, OnInit} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {DialogHeaderComponent} from '../dialog-header/dialog-header.component';
import {UserService} from '../../services/user.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User} from '../../types/user.interface';
import {MockHttpClientPipe} from '../../pipes/mock-data-fetch.pipe';
import {HttpHeaders} from '@angular/common/http';
import {appConfig} from '../../../app-config';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {APP_ROUTES} from '../../app.navigation';
import {environment} from '../../../environments/environment';
import {Subscription} from 'rxjs';
import {UseMockDataService} from '../../services/use-mock-data.service';
import {AutofocusDirective} from '../../directives/autofocus.directive';

@Component({
  selector: 'app-login-dialog',
  standalone: true,
  imports: [SharedModule, DialogHeaderComponent, AutofocusDirective],
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {
  protected readonly environment = environment;

  loginIsPending = false;
  loginForm = new FormGroup({
    email: new FormControl<string>('', [Validators.required, Validators.email]),
    password: new FormControl<string>('', [Validators.required, Validators.min(3)]),
  });

  mockDataForm?: FormGroup;
  mockDataForm$?: Subscription;

  constructor(
    private dialogRef: MatDialogRef<LoginDialogComponent>,
    private elementRef: ElementRef,
    private mockHttpClientPipe: MockHttpClientPipe,
    private useMockDataService: UseMockDataService,
    private router: Router,
    public userService: UserService,
  ) {
  }

  ngOnInit(): void {
    this.initMockDataForm();
  }

  private initMockDataForm() {
    if (!this.environment.features.canUseMockData) return;

    this.mockDataForm = new FormGroup({
      useMockData: new FormControl(this.useMockDataService.getUseMockData())
    });

    this.mockDataForm$ = this.mockDataForm.valueChanges.subscribe(form => {
      this.useMockDataService.setUseMockData(form.useMockData);
      this.userService.unSetUser();
      location.reload();
    });
  }

  onLogin() {
    if (this.loginForm.invalid) return;
    this.loginIsPending = true;

    const newUser = {
      email: this.loginForm.controls.email.value,
      id: 'mockUser',
    } as User;

    this.mockHttpClientPipe.transform({
      method: 'POST',
      url: `login`,
      comment: 'Gebruiker aanmelden',
      elementRef: this.elementRef.nativeElement,
      headers: {
        names: ['Authorization'],
        headers: new HttpHeaders().set('Authorization', `Basic ${btoa(newUser.email + ':' + this.loginForm.controls.password.value)}`)
      },
      delay: 800,
      mockReturnData: {
        name: appConfig.mockValues.loggedInUserName,
        id: appConfig.mockValues.loggedInUserId,
      } as User,
    }).subscribe({
      next: (newUser) => {
        this.loginIsPending = false;
        this.userService.setUser(newUser);
        this.router.navigateByUrl(`/${APP_ROUTES.private}`).then(() => {
          this.dialogRef.close();
        });
      },
      error: () => this.loginIsPending = false
    });
  }

  onReloadClick() {
    location.reload();
  }

  onLogout() {
    this.userService.unSetUser();
  }
}
