import {Component} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {UserService} from '../../services/user.service';
import {AppMenuItems} from 'src/app/app.navigation';

@Component({
  selector: 'app-toolbar-menu',
  templateUrl: './toolbar-menu.component.html',
  styleUrls: ['./toolbar-menu.component.scss'],
  standalone: true,
  imports: [SharedModule]
})
export class ToolbarMenuComponent {
  protected appMenuItems = AppMenuItems;

  constructor(
    public userService: UserService
  ) {
  }
}
