import {Component} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {MatListModule} from '@angular/material/list';
import {UserService} from '../../services/user.service';
import {AppMenuItems} from '../../app.navigation';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss'],
  standalone: true,
  imports: [SharedModule, MatListModule]
})
export class SidenavMenuComponent {
  protected readonly appMenuItems = AppMenuItems;

  constructor(
    public userService: UserService
  ) {
  }

}
